<template>
  <div class="home">
    <img src="../assets/screen.png" />

    <div>
      GF Utils is a set of unofficial utilities to help you with your Glowforge 3D laser printer. When you install the
      (Chrome) Browser Extension and download the iOS or Android app, you can gain some additional benefits, such as:

      <ul>
        <li>
          <b>See countdown timer on your mobile!</b><br />
          Right now you can only see a countdown of time left on your print within the Glowforge Designer tab. GF Utils
          allows you to see the same countdown on your mobile phone!
        </li>
        <li>
          <b><a target="_blank" href="https://ifttt.com/">IFTTT</a> Support</b><br />
          Do cool things like pulse your lights, get phone notifications, work with Amazon Alexa/Google Assistant or
          many more with IFTTT integration.
        </li>
        <li>
          <b><a target="_blank" href="https://www.switch-bot.com/">Switchbot</a> Support (coming soon)</b><br />
          Hate having to press the white button? Use a Switchbot to press it for you.
        </li>
        <li>
          <b>Get Estimated Time project is finishing</b><br />
          Besides just seeing a countdown, get an actual time that the print will finish. Why do math?
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Home",
  // components: {
  //   HelloWorld
  // }
};
</script>

<style scoped>
img {
  width: 400px;
  max-width: 100%;
  float: right;
  border: 1px #000 solid;
  border-radius: 5px;
  margin-left: 20px;
  margin-bottom: 20px;
}
</style>